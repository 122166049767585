import React from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";

import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import "./SuccessPage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";

export default function SuccessPage() {
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        <Navbar />

        <section className="succes-cont">
          <h1>Thank you for</h1>
          <h1>your unwavering support</h1>
          <h1>to a local family facing difficult times.</h1>

          <h2>Your donation has been submitted.</h2>

          <h3>You will receive a confirmation email shortly. </h3>
        </section>

        <Footer />
      </div>
    </NavbarContextProvider>
  );
}
