import React, { useContext } from "react";
import { ThemeContext } from "../utils/NavbarContextProvider";
import "./Hero.css";
import tefilin from "../images/tefilin.svg";
function Hero() {
  const { isNavBarOpen, isManuallyClosed } = useContext(ThemeContext);
  // const handleButtonClick = () => {
  //   window.location.href = "https://davismemorialfund.org/donate/";
  // };
  return (
    <div
      className={`hero ${isNavBarOpen || isManuallyClosed ? "show" : "hide"}`}
    >
      <div className="heroContainer">
          <div className="hero-image-mobile">
            <img src={tefilin} alt="hero" className="heroimageSizeMobile" />
          </div>

          {/* <button onClick={handleButtonClick}>
            DONATE
            <img src={rightArrow} alt="right arrow" />
          </button> */}
        
        <div className="hero-image">
          <img src={tefilin} alt="hero" className="heroimageSize" />
        </div>
        </div>
      </div>
  );
}

export default Hero;
