import React, { useEffect, useState } from "react";
import "./Footer.css";
import FooterLogo from "../images/footer_logo.png";
import FooterLinkColumns from "./FooterLinkColumns";

export default function Footer() {
  // const aboutUs = [
  //   { value: "About Us" },
  //   { value: "About Us" },
  //   { value: "About Us" },
  //   { value: "About Us" },
  // ];

  // const programs = [
  //   { value: "Programs" },
  //   { value: "Programs" },
  //   { value: "Programs" },
  //   { value: "Programs" },
  // ];
  // const partner = [
  //   { value: "Partner" },
  //   { value: "Partner" },
  //   { value: "Partner" },
  //   { value: "Partner" },
  // ];

  const [footerMargin, setFooterMargin] = useState(0);
  const [previousHeight, setPreviousHeight] = useState(window.innerHeight);

  useEffect(() => {
    function checkKeyboard() {
      const currentHeight = window.innerHeight;
      const heightDifference = previousHeight - currentHeight;

      if (heightDifference > 100) {
        // Adjust this threshold as needed
        setFooterMargin(heightDifference);
      } else {
        setFooterMargin(0);
      }

      setPreviousHeight(currentHeight);
    }

    window.addEventListener("resize", checkKeyboard);
    document.addEventListener("focusin", checkKeyboard);
    document.addEventListener("focusout", checkKeyboard);

    return () => {
      window.removeEventListener("resize", checkKeyboard);
      document.removeEventListener("focusin", checkKeyboard);
      document.removeEventListener("focusout", checkKeyboard);
    };
  }, [previousHeight]);

  const contact = [
    { type: "phone", value: "516.295.0296" },
    { type: "email", value: "info@weareboundtogether.org" },
    { value: "25 Lawrence Avenue" },
    { value: "Lawrence, NY 11559" },
  ];
  return (
    <footer className="footer">
      <div className="footer-children">
        <div className="footerLeft">
          <div className="footerLogo">
            <img src={FooterLogo} alt="" />
          </div>
          <div className="resRelText">RESPONSIBLE RELIEF</div>
          <div className="paragraphTextContainer">
            <p>
              Bound Together is a federally approved 501(c)3 charity providing tefilin to the people who need it most.
            </p>
          </div>
          <div className="taxNumberContainer">
            <p className="taxNumber">Tax ID: 04-3720418</p>
          </div>
        </div>
        <span
          style={{
            display: "block",
            width: "100%",
            height: "1px",
            backgroundColor: "white",
            margin: "0 auto",
            marginTop: "30px",
            marginBottom: "30px",
          }}
          className="footerLine"
        ></span>
        <div className="footerRight">
          <div className="footerGrid">
            {/* <FooterLinkColumns data={aboutUs}>ABOUT US</FooterLinkColumns> */}
            {/* <FooterLinkColumns data={programs}>PROGRAMS</FooterLinkColumns> */}
            {/* <FooterLinkColumns data={partner}>PARTNER</FooterLinkColumns> */}
            <FooterLinkColumns data={contact}>CONTACT</FooterLinkColumns>
          </div>

          <div
            className="copyrightTextMobile"
            style={
              {
                // marginBottom: `${footerMargin}px`,
                // marginBottom: "190px",
              }
            }
          >
            <p>All Rights Reserved. ©2023</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
